$darkTheme: #1d1e21;
$darkThemeText: #ffffff;
$darkThemeDark2Text: #8b8c8e;
$secondaryGray3: #26272a;

.container {
  width: 100%;
  height: fit-content;

  padding: 40px 80px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  user-select: none;
}

.header {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: center;
  color: #f4f4f8;
}
.progSelectContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.progSelectText {
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
  color: #7c7d7e;
}
.progSelectContent {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 14px;

  padding: 8px 24px;
  border-radius: 100px;
  border: 1px solid #333333;
  transition: all 250ms ease-out;
  cursor: pointer;

  span {
    font-weight: 700;
    font-size: 14px;
  }
  svg {
    width: 16px;
    transition: all 250ms ease-out;
    transform: rotate(180deg);
  }
  svg path,
  span {
    color: #f4f4f8;
  }
}
.progSelectContentActive {
  border-color: #5f93f8;

  .progDropdown {
    display: flex;
  }
  svg {
    transform: rotate(0);
  }
}
.progDropdown {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 4px);

  display: none;
  flex-direction: column;
  gap: 4px;

  border-radius: 12px;
  background: #2b2e33;
  border: 1px solid rgba(255, 255, 255, 0.04);
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
  padding: 12px 1px;

  max-height: 280px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 3;
}
.progDropdownCell {
  position: relative;
  width: 100%;
  padding: 8px 24px;

  font-weight: 700;
  font-size: 14px;
  color: #7c7d7e;

  transition: all 250ms ease-out;

  &::before {
    content: '';

    width: 4px;

    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    border-radius: 10px;
    background: #5f93f8;
    opacity: 0;
    transition: all 250ms ease-out;
  }
}
.progDropdownCellActive {
  color: #5f93f8;
  background: #f3f7ff0d;

  &::before {
    opacity: 1;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;

  border-radius: 32px;
  border: 1px solid #333333;
}

.chords {
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  overflow-y: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar-thumb {
    height: 73px;
    width: 24px;
    background-clip: padding-box;
    border-radius: 15px;
    border: 8px solid rgba(0, 0, 0, 0);
    background: #333333;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #292a2d;
    height: 3px;
    width: 3px;
  }
}
.chord {
  flex: 1;
  padding: 24px 12px;
  border-radius: 20px;
  border: 1px solid #333333;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  cursor: pointer;
  transition: all 250ms ease-out;
}
.chordActive {
  .chordName,
  .chordDegree {
    color: #5f93f8;
  }
}
.chordFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.chordName {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 18px;
  color: #f4f4f8;

  transition: all 250ms ease-out;
}
.chordDegree {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 12px;
  color: #7c7d7e;

  transition: all 250ms ease-out;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.resetBtn,
.playBtn {
  width: 40px;
  height: 40px;
  border-radius: 100%;

  margin: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #3d3e40;
  transition: all 250ms ease-out;
  cursor: pointer;

  svg {
    width: 28px;
  }
  svg path,
  svg rect {
    color: #e3e3e2;
    transition: all 250ms ease-out;
  }

  &:not(.playBtnActive):not(.resetBtnActive):hover {
    background: #595d62;
  }
}
.playBtn {
  width: 50px;
  height: 50px;

  margin: 0;
}
.playBtnActive {
  background: #5f93f8;

  svg path,
  svg rect {
    color: #ffffff;
  }
}
.keyContainer {
  position: relative;
  width: 56px;
  height: 56px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  border: 1px solid transparent;
  transition: all 250ms ease-out;
  cursor: pointer;
}
.keyContainerActive {
  border-color: #5f93f8;

  .keyTooltip {
    display: flex;
  }
}
.key {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0%;
  text-align: center;
  color: #f4f4f8;
}
.scale {
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center;
  color: #7c7d7e;
}

.keyTooltip {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translate(-50%, -100%);

  width: 220px;
  padding: 16px 16px 24px;

  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #2b2e33;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.04);
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .editBtn {
    width: 100%;
  }
}
.keyTooltipTitle {
  margin-bottom: 6px;

  font-weight: 600;
  font-size: 18px;
  color: #f4f4f8;
}
.keyTooltipText {
  margin-bottom: 12px;

  font-weight: 600;
  font-size: 12px;
  color: #7c7d7e;
}

.footer {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.editBtn {
  padding: 12px 24px;
  border-radius: 100px;
  background: #5f93f8;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0%;
  color: #ffffff;

  &:hover {
  }
}
