.heading {
  color: #333;
  font-size: 54px;
  margin: 58px auto;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 44px;
  }
}

.textContainer {
  padding: 4rem 7.8rem 6rem;

  @media (max-width: 1024px) {
    padding: 0rem 2rem 2rem;
  }

  p,
  ol,
  ul {
    color: #333;
    font-size: 18px;
    font-family: 'Hind', sans-serif;
    line-height: 1.6;
  }
}

.previewCard {
  position: relative;
  margin: 100px;

  &::before {
    content: '';

    width: 100vw;

    position: absolute;
    top: -100px;
    bottom: -100px;
    left: 50%;
    transform: translateX(-50%);

    z-index: -1;
    background: #222326;
  }

  & > div {
    background: #292a2d;
    box-shadow: 0px 0px 0px 10px #ffffff05;
  }
}
