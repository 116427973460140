.container {
  cursor: pointer;
  padding-left: 14px;
}
.active {
  .note {
    background: #5f93f8;
  }
}

.tabsContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 12px;
  overflow: hidden;
}

.background {
  width: 195px;
  height: 136px;
  border-radius: 12px;
  background: #2b2d30;
  position: relative;
}

.strings,
.notes {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.tabLabel {
  position: absolute;
  bottom: -18px;
  color: #7c7d7e;
  font-size: 12px;
  margin-left: -4px;
  font-weight: 500;
  &[data-first-tab='true'] {
    margin-left: 0;
  }
}

.string {
  width: 100%;
  height: 1px;
  background: #dbdbdb;

  &:nth-child(4),
  &:nth-child(5) {
    height: 2px;
  }

  &:nth-child(6) {
    height: 3px;
  }

  &[data-disabled='true'] {
    opacity: 0.4;
  }
}

.note {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #3b3b3b;
  color: #ffffff;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-10px);
  transition: all 250ms ease-out;
  &[data-first-tab='true'] {
    transform: translateX(-6px);
  }
}

.emptyNote,
.unusedNote {
  width: 20px;
  height: 20px;
  margin-left: -20px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: #7c7d7e;
}

.unusedNote {
  color: #7c7d7e;
}

.tabDivider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #333333;
}

.leftBorder {
  border-left: 8px solid #dbdbdb;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
